/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
export const getCardType = function (number) {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) {
    return 'Visa';
  }

  // Mastercard
  re = new RegExp('^5[1-5]');
  if (number.match(re) != null) {
    return 'MasterCard';
  }

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) {
    return 'AmericanExpress';
  }

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null) {
    return 'Discover';
  }

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) {
    return 'DinersClub';
  }

  return 'Other';
};
